export const test = {
  advantages: [
    {
      row: [
        {
          value: "Przedsiębiorczy",
          tooltip:
            "Pociąga go to, co nowe, podejmuje nowe inicjatywy i jest zdecydowany je realizować",
        },
        {
          value: "Elastyczny",
          tooltip:
            "Łatwo przystosowuje się i dobrze się czuje w każdej sytuacji",
        },
        {
          value: "Żywy",
          tooltip:
            "Pełen życia, ruchliwy, Gestykuluje przy użyciu dłoni i ramion, Posługuje się mimiką twarzy",
        },
        {
          value: "Analityczny",
          tooltip:
            "Lubi analizować zadania pod kątem ich logiki i prawidłowości",
        },
      ],
    },
    {
      row: [
        {
          value: "wytrwały",
          tooltip:
            "Doprowadza przedsięwzięcie do samego końca, zanim rozpocznie następne ",
        },
        {
          value: "wesoły",
          tooltip: "Pełen radości i pogodnego humoru",
        },
        {
          value: "przekonywający",
          tooltip:
            "Przekonuje, odwołując się raczej do logiki i faktów, niż do osobistego uroku i siły",
        },
        {
          value: "spokojny",
          tooltip:
            "Wydaje się nieporuszony i opanowany, unika wszelkiej formy konfliktu",
        },
      ],
    },
    {
      row: [
        {
          value: "ugodowy",
          tooltip:
            "Łatwo akceptuje punkt widzenia lub odczuwa niewielką potrzebę przeforsowania własnej opinii ",
        },
        {
          value: "poświęcający się",
          tooltip:
            "Chętnie rezygnuje z osobistych korzyści w imię czegoś lub dla dobra innych",
        },
        {
          value: "lgnie do ludzi",
          tooltip:
            "Ktoś, dla kogo obcowanie z innymi jest raczej okazją do zabawy i przyjemności niż załatwianie interesów",
        },
        {
          value: "o silnej woli",
          tooltip: "Ktoś, kto jest zdecydowany iść własną drogą",
        },
      ],
    },
    {
      row: [
        {
          value: "delikatny",
          tooltip: "Bierze pod uwagę potrzeby i uczucia innych ",
        },
        {
          value: "opanowany",
          tooltip: "Doświadcza rozmaitych wzruszeń, ale rzadko je okazuje",
        },
        {
          value: "rywalizujący",
          tooltip:
            "Każdą sytuację, zdarzenie lub grę przekształca we współzawodnictwo i zawsze chce wygrać",
        },
        {
          value: "urzekający",
          tooltip:
            "Może Cię podbić, pozyskać do czegoś po prostu dzieki czarowi swojej osobowości",
        },
      ],
    },
    {
      row: [
        {
          value: "pokrzepiający",
          tooltip: "Dodaje siły i otuchy, sprawia że inni czują sie lepiej",
        },
        {
          value: "pełen szacunku",
          tooltip: "Traktuje innych poważnie, okazuje im cześć i poszanowanie",
        },
        {
          value: "powściągliwy",
          tooltip: "Oszczędny w wyrażaniu emocji i entuzjazmu",
        },
        {
          value: "zaradny",
          tooltip:
            "Zdolny do szybkiego i skutecznego działania we wszystkich sytuacjach życiowych",
        },
      ],
    },
    {
      row: [
        {
          value: "niewymagający",
          tooltip:
            "Osoba, która z łatwościa akceptuje każdą sytuację i wydarzenie ",
        },
        {
          value: "wrażliwy",
          tooltip:
            "Przejawia troskę o innych, wrażliwy na to, co dzieje się wokół niego",
        },
        {
          value: "samodzielny",
          tooltip:
            "Osoba niezależna, całkowicie polegająca na swoich zdolnościach, ocenach i zasobach",
        },
        {
          value: "pełen werwy",
          tooltip: "Dynamiczny, skłonny do ekscytacji",
        },
      ],
    },
    {
      row: [
        {
          value: "planista",
          tooltip:
            "Woli opracować naprzód szczególy, zadania niezbedne dla realizacji planu lub osiągnięcia celu, jest bardziej zainteresowany planowaniem etapów działania i efektem końcowym, aniżeli wykonaniem zadania",
        },
        {
          value: "cierpliwy",
          tooltip:
            "Nie denerwuje go opóźnianie się czegoś, pozostaje łagodny i tolerancyjny",
        },
        {
          value: "nastawiony na sukces",
          tooltip:
            "Wie, że wszystko będzie dobrze, jeśli o¬n jest za to odpowiedzialny",
        },
        {
          value: "promotor",
          tooltip:
            "Przekonuje i pobudza innych do wytrwałości, przyłączenia się lub zaangażowania, odwołując się do czaru swojej osobowości",
        },
      ],
    },
    {
      row: [
        {
          value: "pewny",
          tooltip: "Niezawodny, rzadko waha się lub zmienia zdanie ",
        },
        {
          value: "spontaniczny",
          tooltip:
            "Chciałby, żeby życie toczyło się żywiołowo, lubi samorzutne działanie, nie ograniczone żadnym planem",
        },
        {
          value: "zorganizowany",
          tooltip:
            "Działa i żyje zgodnie z planem dnia i nie lubi, gdy zostaje o¬n pokrzyżowany",
        },
        {
          value: "nieśmiały",
          tooltip: "Cichy, niełatwo mu zinicjować rozmowę",
        },
      ],
    },
    {
      row: [
        {
          value: "uporządkowany",
          tooltip:
            "Osoba, która porządkuje rzeczy w metodyczny systematyczny sposób ",
        },
        {
          value: "uprzejmy",
          tooltip:
            "Usłużny, skory do robienia czegoś w sposób narzucony przez kogoś",
        },
        {
          value: "szczery",
          tooltip: "Mówi otwarcie i bez ogródek",
        },
        {
          value: "optymista",
          tooltip:
            "Ma pogodne usposobienie, przekonuje siebie i innych, że wszystko potoczy się dobrze",
        },
      ],
    },
    {
      row: [
        {
          value: "życzliwy",
          tooltip:
            "Raczej popiera czyjąś inicjatywę niż sam inicjuje, rzadko rozpoczyna rozmowę",
        },
        {
          value: "wierny",
          tooltip:
            "Można zawsze na nim polegać, niezmienny, lojalny i oddany, czasami ponad granice rozsądku",
        },
        {
          value: "dowcipny",
          tooltip:
            "Tryska humorem i potrafi zwykłą historię przekształcić w zabawne wydarzenie",
        },
        {
          value: "autorytatywny",
          tooltip:
            "Władcza osobowość, której inni nie ośmieliliby się przeciwstawić ",
        },
      ],
    },
    {
      row: [
        {
          value: "odważny",
          tooltip: "Chętnie podejmuje ryzyko, śmiały i nieustraszony",
        },
        {
          value: "czarujący",
          tooltip: "Wesoły, lubi zabawę, towarzystwo",
        },
        {
          value: "dyplomatyczny",
          tooltip: "W kontaktach z ludźmi pełen taktu, wrażliwy i cierpliwy",
        },
        {
          value: "drobiazgowy",
          tooltip:
            "Każdą rzecz robi w określonym porządku, pamietając o wszystkim co trzeba",
        },
      ],
    },
    {
      row: [
        {
          value: "pogodny",
          tooltip: "Zawsze w dobrym nastroju, wzbudzający w innych radość ",
        },
        {
          value: "stały",
          tooltip:
            "Jego emocje znajdują się na niezmiennym poziomie, można łatwo przewidzieć jego reakcje",
        },
        {
          value: "kulturalny",
          tooltip:
            "Ktoś, kto interesuje się zarówno twórczościa intelektualną, jak i artystyczną, taką jak teatr, muzyka symfoniczna, balet",
        },
        {
          value: "pewny siebie",
          tooltip: "przekonany o swoich zdolnościach i osiągnięciach",
        },
      ],
    },
    {
      row: [
        {
          value: "idealista",
          tooltip:
            "Każdą rzecz skłonny jest postrzegać w jej doskonałej formie i sam odczuwa potrzebę dążenia dodoskonałości",
        },
        {
          value: "niezależny",
          tooltip:
            "Samowystarczalny, samodzielny, pewny siebie i zdaje się nie potrzebować pomocy",
        },
        {
          value: "nieszkodliwy",
          tooltip:
            "osoba, która nigdy nie mówi i nie robi nic, co by było nieprzyjemne lub nie na miejscu",
        },
        {
          value: "inspirator",
          tooltip:
            "Zachęca innych do pracy, współdziałania, zaangażowania i czyni z tego rodzaju zabawę",
        },
      ],
    },
    {
      row: [
        {
          value: "wylewny",
          tooltip:
            "Otwarcie wyraża uczucia, zwłaszcza miłości, i chętnie dotyka ludzi rozmawiając z nimi ",
        },
        {
          value: "stanowczy",
          tooltip:
            "Osoba odznaczająca się zdolnością do wydawania szybkich, zwięzłych sądów",
        },
        {
          value: "cięty",
          tooltip:
            "Przejawia humor zaprawiony ironią, zwykle w formie krótkich, sarkastycznych uwag",
        },
        {
          value: "głęboki",
          tooltip:
            "Intensywny w doznaniach, często kieruje uwagę na własne wnętrze, nie angażuje się w płytkie dyskusje",
        },
      ],
    },
    {
      row: [
        {
          value: "rozjemca",
          tooltip:
            "Stale podejmuje się roli mediatora, niweluje różnice, by nie dopuścić do konfliktu ",
        },
        {
          value: "meloman",
          tooltip:
            "Zajmuje się muzyką albo darzy ją ogromnym uznaniem, ceni muzykę raczej jako dziedzinę sztuki niż jako rozrywkę",
        },
        {
          value: "działacz",
          tooltip:
            "kieruje nim potrzeba produktywności, jest przywódcą, za którym inni podążają, trudno mu usiedzieć bezczynnie",
        },
        {
          value: "towarzyski",
          tooltip:
            "Uwielbia przyjęcia i nie może doczekać się spotkaniaze znajomymi, nie nawiązuje kontaktów z obcymi",
        },
      ],
    },
    {
      row: [
        {
          value: "grzeczny",
          tooltip:
            "Osoba, która pamięta o szczególnych okazjach, jest skora do okazywania gestów uprzejmości ",
        },
        {
          value: "nieustępliwy",
          tooltip:
            "Trzyma się twardo, uparcie i nie popuści, dopóki nie osiągnie celu",
        },
        {
          value: "rozmowny",
          tooltip:
            "Nieustannie mówi, najczęściej opowiada śmieszne historie i zabawia wszystkich wokół, czując potrzebę wypełnienia ciszy, tak by inni mogli czuć się dobrze",
        },
        {
          value: "tolerancyjny",
          tooltip:
            "Bez problemu akceptuje poglądy i sposoby postępowania innych, nie odczuwa potrzeby sprzeciwiania się im lub korygowania ich",
        },
      ],
    },
    {
      row: [
        {
          value: "słuchacz",
          tooltip: "Zawsze chętnie wysłucha tego, co masz do powiedzenia ",
        },
        {
          value: "lojalny",
          tooltip:
            "Wierny drugiemu człowiekowi, ideałowi lub pracy, czasami ponad granice rozsądku",
        },
        {
          value: "przywódca",
          tooltip:
            "Urodzony lider,żądny odpowiedzialności, któremu często trudno uwierzyć, że ktoś inny możerównie dobrze wykonać zadanie",
        },
        {
          value: "energiczny",
          tooltip: "Pełen życia, animuszu, wigoru",
        },
      ],
    },
    {
      row: [
        {
          value: "zadowolony",
          tooltip: "Zadowala się tym, co ma, rzadko kiedy zazdrości ",
        },
        {
          value: "szef",
          tooltip:
            "Sprawuje kierownictwo i oczekuje, że ludzie będą mu posłuszni",
        },
        {
          value: "organizator",
          tooltip:
            "Organizuje życie i zadania, rozwiązuje problemy sporządzając wykazy, formularze i wykresy",
        },
        {
          value: "ujmujący",
          tooltip: "Serdeczny, powszechnie lubiany i podziwiany",
        },
      ],
    },
    {
      row: [
        {
          value: "perfekcjonista",
          tooltip:
            "Stawia sobie, a często także innym, wysokie wymagania;pragnie, by wszystko było raz na zawsze należycie uporządkowane ",
        },
        {
          value: "sympatyczny",
          tooltip:
            "Niewymagający, łatwy w kontaktach i w życiu, miły w rozmowie",
        },
        {
          value: "pracowity",
          tooltip:
            "Musi nieustannie pracować lub coś zdobywać, często jest mu bardzo trudno zmusić się do odpoczynku",
        },
        {
          value: "popularny",
          tooltip: "Jest duszą towarzystwa, pożądany gość na przyjęciach",
        },
      ],
    },
    {
      row: [
        {
          value: "żywotny",
          tooltip: "Żywa, spontaniczna osobowość, tryskająca energią ",
        },
        {
          value: "śmiały",
          tooltip:
            "Nie ma obaw, prze do przodu, odważny, nie boi się podejmować ryzyka",
        },
        {
          value: "taktowny",
          tooltip:
            "Zawsze pragnie zachować się zgodnie z tym, co według niego jest właściwe",
        },
        {
          value: "zrównoważony",
          tooltip:
            "Stabilny, o równym usposobieniu, nie podlegający wahaniom w dół lub w górę",
        },
      ],
    },
  ],
  disadvantages: [
    {
      row: [
        { value: "chłodny", tooltip: "Osoba, której twarz nie okazuje emocji" },
        {
          value: "wstydliwy",
          tooltip:
            "Unika zwracaniana siebie uwagi, czuje się wówczas zażenowany",
        },
        {
          value: "buńczuczny",
          tooltip: "Pretnesjonalny, lubi błyszczeć, demonstruje swoją siłę",
        },
        {
          value: "apodyktyczny",
          tooltip:
            "Rozkazujący, dominujący, czasem arogancki w relacjach z innymi",
        },
      ],
    },
    {
      row: [
        {
          value: "niezdyscyplinowany",
          tooltip:
            "Osoba, u której prawie w każdej dziedzinie życia panuje nieład ",
        },
        {
          value: "apatyczny",
          tooltip:
            "Z trudem przychodzi mu ustosunkować się do problemów lub zranień innych",
        },
        {
          value: "beznamiętny",
          tooltip:
            "Nie potrafi się niczym ekscytować, często czując, że to niczego nie zmieni",
        },
        {
          value: "pamiętliwy",
          tooltip:
            "Ktoś, komu trudno jest przebaczyć lub zapomnieć o zranieniach bądź niesprawiedliwości jakiej doznał, skłonny do pielengnowania urazy",
        },
      ],
    },
    {
      row: [
        {
          value: "małomówny",
          tooltip:
            "Niechętny lub przeciwny angażowaniu się, zwłaszcza w coś skomplikowanego ",
        },
        {
          value: "obraźliwy",
          tooltip:
            "Często nosi w sobie negatywne uczucia jako rezultat prawdziwej lub wyimaginowanej obrazy",
        },
        {
          value: "oporny",
          tooltip:
            "Zmaga się, walczy lub waha się przed przyjęciem jakiejkolwiek innej racji poza swoją własną",
        },
        {
          value: "powtarzający się",
          tooltip:
            "Ponownie opowiada te same historie i zdarzenia, żeby cię zabawić, nie uświadamiając sobie, że wcześniej robił to kilka razy, wciąż musi o czymś mówić",
        },
      ],
    },
    {
      row: [
        {
          value: "kapryśny",
          tooltip:
            "Namolny odnośnie do spraw pomniejszych i detali, domaga się zwracania większej uwagi na codzienne drobiazgi",
        },
        {
          value: "lękliwy",
          tooltip:
            "Często doświadcza uczucia głębokiej troski, niepokoju i obaw",
        },
        {
          value: "zapominalski",
          tooltip:
            "Ma słabą pamięć, co zwykle jest związane z brakiem dyscypliny i niechęcią do obciążania pamięci sprawami, które nie są zabawne",
        },
        {
          value: "zuchwały",
          tooltip: "Wprost, otwarcie i bez skrupułów powie dokładnie co myśli",
        },
      ],
    },
    {
      row: [
        {
          value: "niecierpliwy",
          tooltip:
            "Osoba, której trudno jest powstrzymać gniew bądź irytację, lub czekać na innych ",
        },
        {
          value: "niepewny",
          tooltip: "Ktoś lękliwy lub pozbawiony pewności siebie",
        },
        {
          value: "niezdecydowany",
          tooltip:
            "Człowiek, któremu trudno jest podjąć jakąkolwiek decyzję, (Nie chodzi tu o kogoś, kto zastanawia się długo na każdą decyzją, chcąc by była o¬na doskonała)",
        },
        {
          value: "wtrącający się",
          tooltip:
            "Więcej mówi niż słucha, zaczyna mówić nie uświadamiając sobie nawet, że już mówi ktoś inny",
        },
      ],
    },
    {
      row: [
        {
          value: "niepopularny",
          tooltip:
            "Osoba, której napięcie i wygórowane wymagania odpychają innych ",
        },
        {
          value: "odludek",
          tooltip:
            "Niechętnie słucha innych, nie jest zainteresowany uczestnictwem w klubach, grupach, w jakichś formach aktywności, nie obchodzi go życie innych",
        },
        {
          value: "niestały",
          tooltip:
            "W jednej chwili może być w stanie uniesienia, a za moment w dołku, wyraża gotowść pomocy komuś, a następnie znika, obiecuje przyjść, po czym zapomina się pokazać",
        },
        {
          value: "skryty",
          tooltip:
            "Trudno mu przychodzi otwarcie okazać czułość, zarówno fizycznie jak i w słowach",
        },
      ],
    },
    {
      row: [
        { value: "twardogłowy", tooltip: "↵Upiera się przy swoim zdaniu " },
        {
          value: "chaotyczny",
          tooltip:
            "Nie ma stałego sposobu wykonywania rozmaitych czynności i rzeczy",
        },
        {
          value: "wredny",
          tooltip: "Osoba o tak wysokich wymaganiach, że trudno je zaspokoić",
        },
        {
          value: "ociągający się",
          tooltip: "Nieskory do działania i ciężki do zaangażowania się w coś",
        },
      ],
    },
    {
      row: [
        {
          value: "bezbarwny",
          tooltip:
            "Szara osobowość, bez wzlotów i upadków, rzadko jeśli wogóle uzewnętrznia swoje emocje",
        },
        {
          value: "pesymista",
          tooltip:
            "Choć ma nadzieję na najlepsze, zwykle dostrzega najpierw ciemną stronę sytuacji",
        },
        {
          value: "megaloman",
          tooltip:
            "Ktoś, kto ma wysokie mniemanie o sobie, uważa, że zawsze ma rację i że jest najlepszy w pracy",
        },
        {
          value: "na wszystko pozwala",
          tooltip:
            "Pozwala innym (łącznie z dziećmi) robić to, na co mają ochotę, żeby tylko być lubianym",
        },
      ],
    },
    {
      row: [
        {
          value: "złośnik",
          tooltip:
            "Ktoś, kto ma dziecięce, wybuchowe usposobienie, cechujące się napadami złego humoru, które kończą się szybko i są niemal natychmiast zapominane",
        },
        {
          value: "nie mający celu",
          tooltip: "Nie stawia sobie żadnych celów, może ich nawet nie pragnie",
        },
        {
          value: "dyskutant",
          tooltip:
            "Zwykle wznieca spory, ponieważ uważa, że ma rację bez względu na okoliczności",
        },
        {
          value: "wyobcowany",
          tooltip:
            "Łatwo czuje się odsunięty na bok, często z braku poczucia bezpieczeństwa lub z obawy, że inni tak naprawdę nie są zadowoleni z jego towarzystwa",
        },
      ],
    },
    {
      row: [
        {
          value: "naiwny",
          tooltip:
            "Proste, dziecięce widzenie świata, brak doświadczenia lub zrozumienia na czym naprawdę polega życie",
        },
        {
          value: "nastawiony negatywnie",
          tooltip:
            "Ktoś często dostrzegający tylko cienie lub złe strony każdej sytuacji",
        },
        {
          value: "denerwujący",
          tooltip:
            "Pewny siebie, gwałtowny i śmiały, często w negatywnym sensie",
        },
        {
          value: "nonszalancki",
          tooltip: "Niefrasobliwy, beztroski, obojętny",
        },
      ],
    },
    {
      row: [
        {
          value: "zatroskany",
          tooltip:
            "Wciąż czuje się niepewny lub zakłopotany, wiecznie się czymś zamartwia ",
        },
        {
          value: "izolujący się",
          tooltip:
            "Człowiek, który zwraca się ku sobie i odczuwa wielką potrzebę samotności lub czasowego wycofania się",
        },
        {
          value: "pracoholik",
          tooltip:
            "Stawia przed sobą coraz nowe cele, musi być ciągle produktywny i czuje się winny odpoczywając, Kieruje nim nie pragnienie doskonałości czy spełnienia się, ale potrzeba dokonania rozpoczętego zadania i zyskania nagrody",
        },
        {
          value: "lizus",
          tooltip:
            "Zabiega o zaufanie lub aprobatę innych, Jako gospodarz przyjęcia osoba taka łaknie aplauzu, akceptacji publiczności",
        },
      ],
    },
    {
      row: [
        {
          value: "przewrażliwiony",
          tooltip:
            "Nadmiernie analizujący swoje wnętrze, łatwo może poczuć się dotknięty, gdy jest niezrozumiany ",
        },
        {
          value: "nietaktowny",
          tooltip:
            "Czasami zachowuje się w sposób niegrzeczny, a nawet obraźliwy",
        },
        { value: "bojaźliwy", tooltip: "Unika trudnych sytuacji" },
        {
          value: "gadatliwy",
          tooltip:
            "Zabawny, nałogowy gaduła, któremu z trudem przychodzi słuchanie",
        },
      ],
    },
    {
      row: [
        {
          value: "sceptyk",
          tooltip:
            "Charakteryzuje go niepewnośc i brak przekonania, że cokolwiek mu się uda ",
        },
        {
          value: "niezorganizowany",
          tooltip: "Brak mu zdolności do uporządkowania życia",
        },
        {
          value: "dominujący",
          tooltip:
            "Odczuwa przymus panowania na sytuacją i/lub ludźmi, zwykle wskazując innym, co mają robić",
        },
        {
          value: "przygnębiony",
          tooltip: "Osoba, która przez większość czasu jest w stanie depresji",
        },
      ],
    },
    {
      row: [
        {
          value: "niestały",
          tooltip:
            "Niekonsekwentny, przekorny, w swych działaniach i emocjach sprzeczny z logiką",
        },
        {
          value: "zamknięty w sobie",
          tooltip:
            "Osoba, której myśli i zainteresowania sa skierowane do wenątrz, żyjąca w swoim wewnetrznym świecie",
        },
        {
          value: "nietolerancyjny",
          tooltip:
            "Wydaje się niezdolny do zniesienia lub zaakceptowania postaw innych ludzi, ich poglądów bądź sposobów działania",
        },
        {
          value: "obojętny",
          tooltip:
            "Osoba dla której zazwyczaj nie ma znaczenia jak potoczą się sprawy",
        },
      ],
    },
    {
      row: [
        {
          value: "bałaganiarz",
          tooltip: "Żyje w ciągłym nieładzie, nie może znaleźć swoich rzeczy ",
        },
        {
          value: "nastrojowy",
          tooltip:
            "Nie przeżywa wielkich uniesień, ale łatwo wpada w nastrojowy dołek, często gdy czuje się nie doceniany",
        },
        {
          value: "mamrot",
          tooltip:
            "Mówi cicho i niezrozumiale, nie zależy mu na tym aby wypowiadać się wyraźnie",
        },
        {
          value: "manipulant",
          tooltip:
            "Działa lub postępuje w sposób podstępny i przebiegły, obracając wszystko na swoja korzyść, zawsze jakoś wyjdzie na swoje",
        },
      ],
    },
    {
      row: [
        {
          value: "powolny",
          tooltip:
            "Rzadko działa lub myśli szybko, to dla niego zbyt duży wysiłek ",
        },
        {
          value: "uparty",
          tooltip:
            "Zdecydowany przeforsować swoją wolę, nie łatwo go przekonać, zawzięty",
        },
        {
          value: "próżny",
          tooltip:
            "Musi znajdować się w centrum zainteresowania, chce żeby na niego patrzono",
        },
        {
          value: "nieufny",
          tooltip:
            "Nie dowierzający, podający w wątpliwość intencje kryjące się za słowami",
        },
      ],
    },
    {
      row: [
        {
          value: "samotnik",
          tooltip:
            "Potrzebuje dużo czasu dla siebie i ma skłonność do unikania ludzi ",
        },
        {
          value: "pyszałek",
          tooltip:
            "Nie zawaha się dać ci do zrozumienia, że to on ma rację lub decyduje",
        },
        {
          value: "leniwy",
          tooltip:
            "Wszelką pracę i aktywność ocenia pod kątem ilości energii potrzebnej do ich wykonania",
        },
        {
          value: "donośny",
          tooltip:
            "Osoba, której śmiech lub głos wybija się wśród innych w pomieszczeniu",
        },
      ],
    },
    {
      row: [
        {
          value: "niemrawy",
          tooltip:
            "Trudno mu coś rozpocząć, potrzebuje popchnięcia, żeby zostać pobudzonym do działania ",
        },
        {
          value: "podejrzliwy",
          tooltip:
            "Ma skłonnośc nie wierzyć i nie ufać zarówno ludziom, jak i ideom",
        },
        {
          value: "zapalczywy",
          tooltip:
            "Niecierpliwy, łatwo wpada w pełen pretensji gniew, Złości się, gdy inni nie działają dość szybko albo nie zrobili tego, co mieli zrobić",
        },
        {
          value: "roztargniony",
          tooltip: "Nie potrafi się skoncentrować i skupić uwagi, niestały",
        },
      ],
    },
    {
      row: [
        {
          value: "mściwy",
          tooltip:
            "Świadomie lub w inny sposób pielęgnuje urazę i karze krzywdziciela, często subtelnie wycofując się z przyjaźni albo głębszego uczucia",
        },
        {
          value: "niespokojny",
          tooltip:
            "Lubi coraz to nowe formy aktywności, gdyż nie jest rzeczą zabawną robić przez cały czas to samo",
        },
        {
          value: "dystansujący się",
          tooltip: "Nieskłonny lub oporny wobec angażowania się w cokolwiek",
        },
        {
          value: "pochopny",
          tooltip:
            "Może działać w pośpiechu, nie przemyślawszy wszystkiego, zazwyczaj z powodu niecierpliwości",
        },
      ],
    },
    {
      row: [
        {
          value: "ugodowiec",
          tooltip:
            "Często łagodzi swe stanowisko, nawet kiedy ma rację, żeby uniknąć konfliktu ",
        },
        {
          value: "krytykant",
          tooltip:
            "Nieustannie oceniający i ferujący wyroki, często ma negatywne opinnie lub negatywne reakcje",
        },
        {
          value: "przebiegły",
          tooltip:
            "Podstępny, ktoś, kto zawsze potrafi znaleźć drogę, by osiągnąć upragniony cel",
        },
        {
          value: "zmienny",
          tooltip:
            "Ma naturę dziecka, nie potrafi dłużej skupić na czymś uwagi, potrzebuje wielu zmian i różnorodności, by uchronić się przed nudą",
        },
      ],
    },
  ],
};
